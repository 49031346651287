<template>
  <div class="container mx-auto">
    <div class="col-lg-8 offset-lg-2">
      <h1>{{ $t('homepage.heading') }}</h1>

      <p class="mb-4">
        {{ $t('homepage.description') }}
      </p>

      <grouped-matches
        :days-limit="daysLimit"
        :matches="matches.data"
      />

      <h3
        v-if="contentParagraphs.length > 0"
        class="mt-5 fs-2"
      >
        {{ $t('homepage.content.heading') }}
      </h3>

      <div
        v-for="(section, index) in contentParagraphs"
        :key="index"
        v-html="section"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { CACHE_KEY_CONTENT, CACHE_KEY_SPORT_EVENTS, TIME_HOUR } from '~/config/cache';
import { DATE_YYYY_MM_DD, DOMAIN_MX, HOMEPAGE_DAYS_MORE } from '~/config/global';
import { getJsonLdMatch } from '~/utils/jsonld';
import GroupedMatches from '~/components/homepage/GroupedMatches.vue';

export default defineComponent({
  name: 'HomePage',

  components: {
    GroupedMatches,
  },

  jsonld() {
    const { matches } = this;

    if (!matches) {
      return null;
    }

    const { data } = matches;

    if (!Array.isArray(data)) {
      return null;
    }

    return data.map((match) => getJsonLdMatch(match, {
      $i18n: this.$i18n,
      withOffer: false,
      formatDate: (date) => this.$dateFns.formatISO(date),
      resolveRoute: (routeInput) => {
        const path = this.localePath(routeInput);
        const baseUrl = this.$i18n.baseUrl;

        return `${baseUrl}${path}`;
      },
    }));
  },

  async setup() {
    const app = useNuxtApp();
    const { $dateFns, $cacheFetch, $cacheFetchDomain } = app;
    const timezone = useTimezone().value;
    const { t } = useI18n();

    useSeoMeta({
      title: t('homepage.seo.title'),
    });

    const limit = 100;
    const today = new Date();
    const from = $dateFns.format(today, DATE_YYYY_MM_DD);
    const daysLimit = HOMEPAGE_DAYS_MORE;
    const to = $dateFns.format($dateFns.addDays(today, daysLimit), DATE_YYYY_MM_DD);
    const statuses = 'not_started,posponed';
    const params = {
      from,
      to,
      limit,
      statuses,
      tz: timezone,
      offset: 0,
      has_transmissions: 1,
      geo_alpha2: 'MX',
      domain: DOMAIN_MX,
    };

    const contentParams = {
      page: 'homepage',
      webpage: 'futbolhoyenvivo.mx',
    };

    const [
      { data: matches, error: matchesError },
      { data: contentResponse, error: contentError },
    ] = await Promise.all([
      useAsyncData('homepage-matches', () => $cacheFetch({
        url: '/soccer/api/sport-events',
        key: parseCacheKey(CACHE_KEY_SPORT_EVENTS, params),
        params,
        maxAge: TIME_HOUR,
      })),
      useAsyncData('homepage-content', () => $cacheFetchDomain({
        url: '/page-contents',
        key: parseCacheKey(CACHE_KEY_CONTENT, contentParams),
        params: contentParams,
        maxAge: TIME_HOUR,
      })),
    ]);

    if (matchesError.value) {
      throw matchesError.value;
    }

    if (contentError.value) {
      throw contentError.value;
    }

    return {
      matches,
      content: contentResponse.value.data,
      daysLimit,
    };
  },

  computed: {
    contentParagraphs() {
      const { content } = this;

      if (!Array.isArray(content)) {
        return [];
      }

      return content.map((item) => item.content);
    },
  },
});
</script>
